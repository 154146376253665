import React from 'react';
import type { AppProps } from 'next/app';
import type { NextPage } from 'next';
import type { ReactElement, ReactNode } from 'react';
import { appWithTranslation } from 'next-i18next';
import { GlobalStyle } from 'styles/global-style';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';
import Head from 'next/head';
import Script from 'next/script';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=UA-155383501-2"
      ></Script>
      <Script id="gtag">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-155383501-2');
          `}
      </Script>
      <Head>
        <meta
          name="viewport"
          content="target-densitydpi=device-dpi, width=device-width, initial-scale=1"
        />
      </Head>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        {getLayout(<Component {...pageProps} />)}
      </ThemeProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
